import React from 'react'

import { CheckIcon } from '@radix-ui/react-icons'
import 'twin.macro'

import { useHowItWorksPhaseTwo } from 'content-queries/mx/how-it-works-phase-2'

import {
  CategoryListText,
  CategoryPrimaryText,
  Container,
  Content,
  Line,
  PhaseCategory,
  PhasePre,
  PhaseTitle,
  Step,
  Stepper,
} from './container'
import { FullSizedSamples, ProjectRendering } from './phase-sections'
import { Parallax } from '../components/parallax'
import { Section } from '../section'

const PhaseTwo = ({ phase }: { phase: number }) => {
  const { data } = useHowItWorksPhaseTwo()
  const [
    {
      node: {
        title,
        image,
        meta: {
          expectationDetails,
          post,
          priceDetails: { price, label },
          pre,
        },
        sectionModules: [fullSizedSamples, projectRendering],
      },
    },
  ] = data
  return (
    <Section padding="none">
      <Container>
        <Stepper tw="row-span-2">
          <Step>0{phase}</Step>
          <Line />
          <PhasePre>{pre}</PhasePre>
        </Stepper>
        <Content tw="row-span-1 pb-24 pt-8 md:(py-24)">
          <div tw="col-span-full flex flex-col md:(flex-row items-center)">
            <div tw="flex flex-col md:(w-1/2)">
              <div tw="space-y-1">
                <PhaseTitle>{title}</PhaseTitle>
              </div>
              <div tw="h-full relative py-16 px-10 md:(hidden)">
                <Parallax tw="w-full">
                  <img src={image.file.url} alt={image.title} />
                </Parallax>
              </div>
              <div tw="flex flex-col space-y-1 md:(flex-row pt-8 space-x-20)">
                <div>
                  <PhaseCategory>{label}</PhaseCategory>
                  <CategoryPrimaryText>{price}</CategoryPrimaryText>
                </div>
                <div tw="space-y-2 pt-8 md:pt-0">
                  <PhaseCategory>{expectationDetails?.label}</PhaseCategory>
                  <div tw="space-y-3 text-gray-500 pl-4">
                    {expectationDetails?.expectations.map(
                      (expectation: string, index: number) => (
                        <div tw="flex space-x-2 items-center" key={index}>
                          <div>
                            <CheckIcon />
                          </div>
                          <CategoryListText
                            dangerouslySetInnerHTML={{
                              __html: expectation,
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
              <div tw="pt-8">
                <CategoryListText tw="text-gray-400">{post}</CategoryListText>
              </div>
            </div>
            <div tw="hidden md:(h-full relative w-1/2 flex items-center justify-center)">
              <Parallax tw="w-10/12">
                <img src={image.file.url} alt={image.title} />
              </Parallax>
            </div>
          </div>
        </Content>

        <Content tw="row-span-1 space-y-20 mb-32 md:(space-y-32) lg:(px-0)">
          <FullSizedSamples content={fullSizedSamples} />
          <ProjectRendering content={projectRendering} />
        </Content>
      </Container>
    </Section>
  )
}

export { PhaseTwo }
