import React, { useState, useRef, useLayoutEffect, ReactNode } from 'react'

import { ResizeObserver } from '@juggle/resize-observer'
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring,
  useReducedMotion,
} from 'framer-motion'

type ParallaxProps = {
  children: ReactNode
  className?: string
  offset?: number
}

const Parallax = ({
  children,
  offset = 50,
  className,
  ...props
}: ParallaxProps): JSX.Element => {
  const prefersReducedMotion = useReducedMotion()
  const [elementTop, setElementTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const ref = useRef<HTMLDivElement | null | undefined>(null)

  const { scrollY } = useViewportScroll()

  // start animating our element when we've scrolled it into view
  const initial = elementTop - clientHeight
  // end our animation when we've scrolled the offset specified
  const final = elementTop + offset

  const yRange = useTransform(scrollY, [initial, final], [offset, -offset])
  // apply a spring to ease the result
  const y = useSpring(yRange, { stiffness: 400, damping: 90 })
  const opacity = useTransform(y, [100, 0, -100], [1, 1, 1])

  useLayoutEffect(() => {
    // return if there is no element to work with
    if (
      typeof ref !== 'object' ||
      ref === null ||
      !(ref.current instanceof Element)
    ) {
      return
    }

    const element = ref.current

    // save our layout measurements in a function in order to trigger
    // it both on mount and on resize
    const ro = new ResizeObserver(() => {
      setElementTop(
        element.getBoundingClientRect().top + window.scrollY ||
          window.pageYOffset,
      )
      setClientHeight(window.innerHeight)
    })

    ro.observe(element)

    return () => {
      ro.unobserve(element)
      ro.disconnect()
    }
  }, [ref])

  // Don't parallax if the user has "reduced motion" enabled
  if (prefersReducedMotion) {
    return <>{children}</>
  }

  return (
    <motion.div
      ref={ref as React.MutableRefObject<HTMLDivElement>}
      style={{ y, opacity }}
      {...props}
      className={className}
    >
      {children}
    </motion.div>
  )
}

export { Parallax }
