import { graphql, useStaticQuery } from 'gatsby'

export const useHowItWorks = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "3nPmClbayvoxRBJKP0ieeW" } }
      ) {
        data: edges {
          node {
            title
            description

            sectionModules {
              moduleTitle
              moduleMisc {
                post
              }

              contentSlots {
                slotTitle
                description
              }
            }
          }
        }
      }
    }
  `)
  return content
}
