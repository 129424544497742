import React from 'react'

import { navigate } from 'gatsby'
import tw from 'twin.macro'

import { useHowItWorks } from 'content-queries/mx/how-it-works'

import { CategoryListText } from './container'
import { MxButton as Button } from '../components/section-components'
import { Section } from '../section'

const Hero = () => {
  const { data } = useHowItWorks()

  const {
    node: {
      title,
      description,
      sectionModules: [{ contentSlots: timelineSteps }],
    },
  } = data[0]

  return (
    <Section css={[tw`bg-gray-50`]} padding="top">
      <div
        tw="pt-0 grid grid-cols-6 gap-y-4 md:grid-cols-9 md:(items-end) lg:(gap-y-8 grid-cols-16 auto-rows-auto)"
        style={{ gridAutoRows: 'minmax(min-content, max-content)' }}
      >
        <div tw="space-y-6 pl-6 col-start-1 col-end-7 md:(col-start-1 col-end-7 pl-4) lg:(col-start-3 col-end-10 shrink-0 pl-0)">
          <h1 tw="text-4xl font-thin leading-tighter text-black max-w-xs md:(max-w-xl)">
            {title}
          </h1>
        </div>
        <p tw="pl-6 col-span-full text-lg font-light text-gray-500 max-w-xs col-end-14 lg:(pl-0 col-start-3 col-end-10)">
          {description}
        </p>
      </div>
      <Section padding="none" css={[tw`bg-gray-50 py-4 md:pt-12`]}>
        <div
          tw="pb-8 grid grid-cols-6 gap-y-4 md:(grid-cols-9 items-end) lg:(gap-y-8 grid-cols-16 auto-rows-auto)"
          style={{ gridAutoRows: 'minmax(min-content, max-content)' }}
        >
          <div tw="col-span-full relative md:(col-start-1 col-end-10 grid grid-cols-16) lg:(col-end-17)">
            <div tw="grid gap-y-12 grid-cols-6 col-span-full auto-rows-max md:(grid-cols-16)">
              {timelineSteps.map((s: any, idx: number) => (
                <div
                  key={idx}
                  tw="pl-6 col-start-1 col-end-6 md:(col-start-3 col-end-11) xl:(col-end-9)"
                >
                  <div tw="flex flex-col h-full justify-between">
                    <div tw="flex flex-col">
                      <p tw="text-3xl font-thin text-gray-900 leading-none pt-5 pb-3">
                        {s?.slotTitle}
                      </p>
                      <CategoryListText tw="text-gray-600 md:text-base">
                        {s?.description}
                      </CategoryListText>
                    </div>
                  </div>
                </div>
              ))}
              <div tw="grid pl-6 col-span-full gap-y-8 md:( gap-y-6 col-start-3 col-end-16)">
                <div tw="col-span-full">
                  <CategoryListText tw="text-gray-500">
                    *All deposits are redeemable against your final purchase.
                  </CategoryListText>
                </div>
                <div tw="col-span-full justify-self-center my-auto md:justify-self-start">
                  <Button
                    kind="link"
                    onClick={() => {
                      navigate('/welcome/new/timeline')
                    }}
                  >
                    Current lead times
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Section>
  )
}

export { Hero }
