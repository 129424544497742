import { graphql, useStaticQuery } from 'gatsby'

export const useHomeExperience = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "57a4FU661HsIywAclTUn8Z" } }
      ) {
        data: edges {
          node {
            title
            description
            actions {
              link
              text
            }
            sectionModules {
              moduleTitle
              images {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 800
                      formats: [PNG]
                    )
                  }
                }
              }
              contentSlots {
                slotTitle
                description
                textparagraphquote {
                  textparagraphquote
                }
                meta {
                  icon
                }
                photos {
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED
                        width: 800
                        formats: [PNG]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
