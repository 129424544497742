import React from 'react'

import { CheckIcon } from '@radix-ui/react-icons'
import { navigate } from 'gatsby'
import tw from 'twin.macro'

import { useHowItWorksPhaseFour } from 'content-queries/mx/how-it-works-phase-4'
import { Parallax } from 'mx/components/parallax'
import { MxButton as Button } from 'mx/components/section-components'
import {
  CategoryListText,
  CategoryPrimaryText,
  Container,
  Content,
  Description,
  Line,
  PhaseCategory,
  PhasePre,
  PhaseTitle,
  Step,
  Stepper,
} from 'mx/how-it-works/container'
import { Section } from 'mx/section'

const PhaseFour = ({ phase }: { phase: number }) => {
  const { data } = useHowItWorksPhaseFour()
  const [
    {
      node: {
        title,
        description,
        image,
        meta: {
          expectationDetails,
          priceDetails: { price, label },
          pre,
        },
        sectionModules: [benefits],
      },
    },
  ] = data

  return (
    <Section padding="none">
      <Container>
        <Stepper tw="row-span-2">
          <Step>0{phase}</Step>
          <Line />
          <PhasePre>{pre}</PhasePre>
        </Stepper>
        <Content tw="row-span-1 pb-24 pt-8 md:(py-24)">
          <div tw="col-span-full flex flex-col md:(flex-row items-center)">
            <div tw="flex flex-col md:(w-1/2)">
              <div>
                <PhaseTitle>{title}</PhaseTitle>
                <Description>{description}</Description>
              </div>
              <div tw="h-full relative py-16 px-10 md:(hidden)">
                <Parallax tw="w-full">
                  <img src={image.file.url} alt={image.title} />
                </Parallax>
              </div>
              <div tw="flex flex-col space-y-1 md:(flex-row pt-8 space-x-20)">
                <div>
                  <PhaseCategory>{label}</PhaseCategory>
                  <CategoryPrimaryText>{price}</CategoryPrimaryText>
                </div>
                <div tw="space-y-2 pt-8 md:pt-0">
                  <PhaseCategory>{expectationDetails?.label}</PhaseCategory>
                  <div tw="space-y-3 text-gray-500 pl-4">
                    {expectationDetails?.expectations.map(
                      (expectation: string, index: number) => (
                        <div tw="flex space-x-2 items-center" key={index}>
                          <div>
                            <CheckIcon />
                          </div>
                          <CategoryListText
                            dangerouslySetInnerHTML={{
                              __html: expectation,
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div tw="hidden md:(h-full relative w-1/2 flex items-center justify-center)">
              <Parallax tw="w-10/12">
                <img src={image.file.url} alt={image.title} />
              </Parallax>
            </div>
          </div>
        </Content>
        <Content
          tw="py-4 flex row-span-1 gap-x-8 md:(py-0 grid grid-cols-14)"
          className="scroll-snap-x"
        >
          {benefits.contentSlots.map((item: any, index: number) => (
            <div
              key={index}
              css={[index === 0 && tw`!col-start-3`]}
              tw="col-span-4 space-y-4 w-9/12 shrink-0 px-8 md:(px-0 w-11/12)"
              className="scroll-snap-member"
            >
              <div tw="flex flex-col h-full">
                <div tw="w-full px-8 py-4">
                  <img src={item.photos[0]?.file.url} />
                </div>
                <div tw="flex flex-col justify-between h-full">
                  <div tw="space-y-2">
                    <p tw="font-thin text-gray-700 text-2xl leading-tight md:(leading-snug text-xl)">
                      {item.slotTitle}
                    </p>
                    <p tw="text-gray-500 font-light text-base">
                      {item.description}
                    </p>
                  </div>
                  <div tw="pt-5 text-center">
                    <Button
                      kind="link"
                      onClick={() => {
                        if (item.actionUrl.match('^\\/(?!\\/)')) {
                          navigate(item.actionUrl)
                        } else {
                          window.open(item.actionUrl, '_blank')
                        }
                      }}
                    >
                      {item.actionText}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Content>
      </Container>
    </Section>
  )
}

export { PhaseFour }
