/* eslint-disable no-unsafe-optional-chaining */
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@radix-ui/react-icons'
import { navigate } from 'gatsby'
import { GatsbyImage, getSrc, IGatsbyImageData } from 'gatsby-plugin-image'
import ReactCompareImage from 'react-compare-image'
import { useMedia } from 'react-use'
import { styled } from 'twin.macro'

import { useHomeExperience } from 'content-queries/mx/home-experience'
import { Parallax } from 'mx/components/parallax'
import { MxButton as Button } from 'mx/components/section-components'
import { Description, PhaseSecondaryTitle } from 'mx/how-it-works/container'

const ShowYourSpace = ({ content }: { content: any }) => {
  const {
    moduleMisc: { description },
    moduleTitle: title,
    contentSlots,
  } = content

  return (
    <div tw="flex flex-col w-full space-y-0 md:(flex-row items-center)">
      <div tw="flex flex-col relative md:(w-5/12 py-12)">
        <PhaseSecondaryTitle tw="max-w-xl w-5/6">{title}</PhaseSecondaryTitle>
        <Description tw="max-w-xs">{description}</Description>
      </div>
      <div tw="relative flex flex-col justify-end pt-12 md:(w-7/12 mt-auto)">
        <div tw="flex flex-row space-x-2">
          <Parallax tw="relative bg-gray-50 rounded-md flex flex-row items-end space-x-4 p-4 w-8/12">
            <div>
              <GatsbyImage
                image={
                  contentSlots?.[0].photos?.[0]?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={contentSlots?.[0].photos?.[0]?.title}
              />
            </div>
            <div>
              <GatsbyImage
                image={
                  contentSlots?.[0].photos?.[1]?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={contentSlots?.[0].photos?.[1]?.title}
              />
            </div>
          </Parallax>
          <Parallax tw="relative bg-gray-50 rounded-md w-4/12 p-4">
            <GatsbyImage
              image={
                contentSlots?.[0].photos?.[2]?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={contentSlots?.[0].photos?.[2]?.title}
            />
          </Parallax>
        </div>
        <Parallax offset={35} tw="w-full bg-gray-50 rounded-md p-4">
          <div tw="flex justify-between border-b border-gray-200">
            <div tw="flex space-x-1 mb-2">
              <div tw="w-4 h-4 rounded-full border border-gray-600" />
              <div tw="w-4 h-4 rounded-full border border-gray-600" />
              <div tw="w-4 h-4 rounded-full border border-gray-600" />
            </div>
          </div>
          <div></div>
          <div tw="grid grid-cols-3 gap-y-4 gap-x-4">
            {contentSlots?.[1].photos?.map((photo: any, index: number) => {
              return (
                <GatsbyImage
                  key={index}
                  tw="md:(h-full)"
                  image={photo.localFile?.childImageSharp?.gatsbyImageData}
                  alt={photo.title}
                />
              )
            })}
          </div>
        </Parallax>
      </div>
    </div>
  )
}

const WorkWithYourDesigner = ({ content }: { content: any }) => {
  const {
    moduleMisc: { icon, pre, description },
    moduleTitle: title,
    contentSlots,
  } = content
  const isWide = useMedia('(min-width: 640px)')

  return (
    <div tw="w-full flex flex-col md:flex-row w-full md:items-center relative">
      <div tw="space-y-4 md:space-y-0 py-2 md:w-5/12 lg:w-6/12 md:order-2">
        <div>
          <div tw="mb-4 flex items-center space-x-2 text-gray-400">
            <div tw="w-6 h-6 flex items-center justify-center rounded-full border border-gray-400">
              <FontAwesomeIcon tw="w-3 h-3" icon={['fal', icon]} />
            </div>
            <p tw="capitalize text-sm leading-none">{pre}</p>
          </div>
          <PhaseSecondaryTitle tw="">{title}</PhaseSecondaryTitle>
          <Description tw="md:leading-relaxed">{description}</Description>
        </div>
      </div>

      <div
        tw="w-full py-4 md:w-7/12 lg:w-6/12 md:mr-5 md:order-1 flex md:flex-wrap relative space-x-4 md:space-x-0 md:space-y-6 md:py-4 items-stretch"
        className="scroll-snap-x"
      >
        {contentSlots.map((slot: any) => (
          <Parallax
            offset={isWide ? -20 : 0}
            tw="bg-white md:bg-transparent w-9/12 md:w-full shrink-0 ml-2 md:ml-0 border border-t md:border-r-0 md:border-l-0 border-b border-dashed"
            className="scroll-snap-member"
          >
            <div tw="flex flex-col md:flex-row rounded-t-md md:rounded-t-none md:rounded-l-md overflow-hidden">
              <div tw="h-32 md:h-auto md:w-3/12 rounded-t-md md:rounded-t-none md:rounded-l-md overflow-hidden relative shrink-0">
                <GatsbyImage
                  tw="h-full rounded-t-md md:rounded-l-md md:rounded-t-none overflow-hidden"
                  alt="coffee routine"
                  image={
                    slot?.photos?.[0]?.localFile.childImageSharp.gatsbyImageData
                  }
                />
              </div>
              <div tw="space-y-4 px-4 md:pl-6 md:space-y-4 py-6 w-full flex flex-col justify-between md:py-4">
                <div>
                  <p tw="font-thin text-gray-700 text-2xl">{slot.slotTitle}</p>
                </div>
                <p tw="text-base text-gray-600 md:max-w-xs">
                  {slot.description}
                </p>
              </div>
            </div>
          </Parallax>
        ))}
      </div>
    </div>
  )
}

const FullSizedSamples = ({ content }: { content: any }) => {
  const {
    moduleMisc: { description },
    moduleTitle: title,
  } = content

  const { data: experienceData } = useHomeExperience()
  const [
    {
      node: {
        sectionModules: [, , experienceDataThird],
      },
    },
  ] = experienceData
  return (
    <div tw="w-full flex flex-col md:flex-row w-full md:items-center space-y-8">
      <div tw="pb-2 md:w-6/12 space-y-8">
        <PhaseSecondaryTitle>{title}</PhaseSecondaryTitle>
        <Description tw="max-w-sm md:leading-relaxed">
          {description}
        </Description>
      </div>
      <div tw="flex justify-center relative md:(w-6/12 order-1)">
        <div tw="w-full mx-auto flex flex-row flex-wrap md:mx-0 relative">
          {experienceDataThird?.contentSlots?.[0]?.photos?.map(
            (front: any, index: number) => {
              const checkedItems = [0, 5, 6]
              const renderCheckIcon = checkedItems.includes(index)

              return (
                <div
                  tw="flex w-1/4 justify-center items-center p-2 "
                  key={index}
                >
                  <GatsbyImage
                    alt={front.title}
                    tw="h-full"
                    image={front.localFile.childImageSharp.gatsbyImageData}
                  />
                  {renderCheckIcon && (
                    <div tw="w-8 h-8 flex items-center justify-center rounded-full absolute bg-white z-10 text-gray-700 shadow-md lg:(w-10 h-10)">
                      <CheckIcon tw="w-6 h-6" color="currentColor" />
                      <div tw="w-8 h-8 flex items-center justify-center rounded-full absolute opacity-30 bg-white transform animate-ping lg:(w-10 h-10)"></div>
                    </div>
                  )}
                </div>
              )
            },
          )}
        </div>
      </div>
    </div>
  )
}

const ProjectRendering = ({ content }: { content: any }) => {
  const {
    moduleMisc: { description, pre, icon },
    moduleTitle: title,
    contentSlots,
  } = content

  const photos = contentSlots?.[0]?.photos

  return (
    <div>
      <div tw="space-y-4 overflow-hidden md:space-y-8 md:items-center md:flex md:flex-col">
        <div tw="flex flex-col md:items-center space-y-4">
          <div tw="flex flex-col items-start md:items-center">
            <div tw="mb-4 flex items-center space-x-2 text-gray-400">
              <div tw="w-6 h-6 flex items-center justify-center rounded-full border border-gray-400">
                <FontAwesomeIcon tw="w-3 h-3" icon={['fal', icon]} />
              </div>
              <p tw="capitalize text-sm leading-none">{pre}</p>
            </div>
            <PhaseSecondaryTitle tw="md:(text-center max-w-full)">
              {title}
            </PhaseSecondaryTitle>
          </div>

          <Description tw="md:my-0 max-w-md md:text-center">
            {description}
          </Description>
        </div>
        <div tw="w-full mx-auto">
          <BeforeAfterSlider
            beforeImage={photos[1].localFile.childImageSharp.gatsbyImageData}
            afterImage={photos[0].localFile.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div tw="w-full mx-auto">
        <div tw="hidden py-6 md:block">
          <div tw="py-4">
            <blockquote tw="flex justify-evenly items-center space-x-6">
              <footer tw="flex space-x-3 items-center">
                <div tw="w-9 h-full overflow-hidden rounded-full">
                  <GatsbyImage
                    alt={`FORM client ${contentSlots?.[0]?.meta?.client}`}
                    image={
                      photos?.[2]?.localFile.childImageSharp.gatsbyImageData
                    }
                  />
                </div>
                <div tw="font-light pl-2">
                  <p tw="text-sm text-gray-500">
                    {contentSlots?.[0]?.meta?.client}
                  </p>
                  <Button
                    kind="link"
                    onClick={() => {
                      navigate(contentSlots?.[0]?.meta?.action?.link)
                    }}
                  >
                    {contentSlots?.[0]?.meta?.action?.text}
                  </Button>
                </div>
              </footer>
              <div>
                <p tw="font-thin text-xl leading-snug text-gray-700 max-w-2xl">
                  "{contentSlots?.[0]?.meta?.quote}"
                </p>
              </div>
            </blockquote>
          </div>
        </div>
        <div tw="border-t border-dotted border-emerald-200 pl-4 mt-4 py-8 max-w-lg md:(hidden)">
          <blockquote>
            <div>
              <p tw="font-thin text-xl leading-snug text-gray-700 max-w-xs">
                "{contentSlots?.[0]?.meta?.quote}"
              </p>
            </div>
            <footer tw="mt-6 flex items-center">
              <div tw="w-9 h-9 overflow-hidden rounded-full">
                <GatsbyImage
                  alt={`FORM client ${contentSlots?.[0]?.meta?.client}`}
                  image={photos?.[2]?.localFile.childImageSharp.gatsbyImageData}
                />
              </div>
              <div tw="font-light pl-2">
                <p tw="text-sm text-gray-500">
                  {contentSlots?.[0]?.meta?.client}
                </p>
                <Button
                  kind="link"
                  onClick={() => {
                    navigate(contentSlots?.[0]?.meta?.action?.link)
                  }}
                >
                  {contentSlots?.[0]?.meta?.action?.text}
                </Button>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  )
}

const BeforeAfterSlider = ({
  beforeImage,
  afterImage,
}: {
  beforeImage: IGatsbyImageData
  afterImage: IGatsbyImageData
}) => {
  return (
    <SliderContainer>
      <ReactCompareImage
        handle={
          <SliderHandle>
            <ChevronLeftIcon />
            <ChevronRightIcon />
          </SliderHandle>
        }
        leftImage={getSrc(beforeImage)}
        rightImage={getSrc(afterImage)}
        sliderLineWidth={5}
        sliderPositionPercentage={0.7}
      />
    </SliderContainer>
  )
}
const SliderContainer = styled.section`
  img {
    margin: 0;
  }
`

const SliderHandle = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  font-size: 11px;
  height: 38px;
  justify-content: space-between;
  width: 38px;
  padding: 8px;
`

export {
  ShowYourSpace,
  WorkWithYourDesigner,
  FullSizedSamples,
  ProjectRendering,
}
