import { graphql, useStaticQuery } from 'gatsby'

export const useHowItWorksPhaseTwo = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "6gGVdnh7cHGDjDdtS24TYQ" } }
      ) {
        data: edges {
          node {
            title
            description
            meta {
              pre
              priceDetails {
                price
                label
              }
              expectationDetails {
                label
                expectations
              }
              post
            }
            image {
              file {
                url
              }
            }
            sectionModules {
              moduleTitle
              moduleMisc {
                description
                pre
                icon
              }
              contentSlots {
                slotTitle
                description
                meta {
                  pre
                  quote
                  client
                  action {
                    link
                    text
                  }
                }
                photos {
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
