import { graphql, useStaticQuery } from 'gatsby'

export const useHowItWorksPhaseThree = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "5CpjRHZss1Mn0OKcbfihzj" } }
      ) {
        data: edges {
          node {
            title
            meta {
              pre
              priceDetails {
                price
                label
              }
              post
              expectationDetails {
                label
                expectations
              }
            }
            image {
              file {
                url
              }
            }
            sectionModules {
              moduleTitle
              moduleMisc {
                description
                pre
                rooms
                icon
              }
              images {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 120)
                  }
                }
              }
              contentSlots {
                photos {
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, width: 1200)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}
