import React from 'react'

import 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CheckIcon } from '@radix-ui/react-icons'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useHowItWorksPhaseThree } from 'content-queries/mx/how-it-works-phase-3'
import { Parallax } from 'mx/components/parallax'
import { MxSectionSecondContainer } from 'mx/components/section-components'
import {
  CategoryListText,
  CategoryPrimaryText,
  Container,
  Content,
  Description,
  Line,
  PhaseCategory,
  PhasePre,
  PhaseSecondaryTitle,
  PhaseTitle,
  Step,
  Stepper,
} from 'mx/how-it-works/container'
import { Section } from 'mx/section'

const PhaseThree = ({ phase }: { phase: number }) => {
  const { data: phase3Data } = useHowItWorksPhaseThree()

  const [
    {
      node: {
        title,
        image,
        meta: {
          pre,
          expectationDetails,
          post,
          priceDetails: { price, label },
        },
        sectionModules: [beyondKitchen, app, measurements],
      },
    },
  ] = phase3Data

  const {
    moduleMisc: { description: appDescription, pre: appPre, icon: appIcon },
    moduleTitle,
    contentSlots: [{ photos: appPhotos }],
  } = app

  const {
    moduleTitle: measurementsTitle,
    moduleMisc: { description: measurementsDescription },
    contentSlots: [{ photos: measurementsPhotos }],
  } = measurements

  return (
    <Section padding="none">
      <Container>
        <Stepper tw="row-span-2">
          <Step>0{phase}</Step>
          <Line />
          <PhasePre>{pre}</PhasePre>
        </Stepper>
        <Content tw="row-span-1 pb-24 pt-8 md:(py-24)">
          <div tw="col-span-full flex flex-col md:(flex-row items-center)">
            <div tw="flex flex-col md:(w-1/2)">
              <div tw="space-y-1">
                <PhaseTitle>{title}</PhaseTitle>
              </div>
              <div tw="h-full relative py-16 px-10 md:(hidden)">
                <Parallax tw="w-full">
                  <img src={image.file.url} alt={image.title} />
                </Parallax>
              </div>
              <div tw="flex flex-col space-y-1 md:(flex-row pt-8 space-x-20)">
                <div>
                  <PhaseCategory>{label}</PhaseCategory>
                  <CategoryPrimaryText>{price}</CategoryPrimaryText>
                </div>
                <div tw="space-y-2 pt-8 md:pt-0">
                  <PhaseCategory>{expectationDetails?.label}</PhaseCategory>
                  <div tw="space-y-3 text-gray-500 pl-4">
                    {expectationDetails?.expectations.map(
                      (expectation: string, index: number) => (
                        <div tw="flex space-x-2 items-center" key={index}>
                          <div>
                            <CheckIcon />
                          </div>
                          <CategoryListText
                            dangerouslySetInnerHTML={{
                              __html: expectation,
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
              <div tw="pt-8">
                <CategoryListText tw="text-gray-400">{post}</CategoryListText>
              </div>
            </div>
            <div tw="hidden md:(h-full relative w-1/2 flex items-center justify-center)">
              <Parallax tw="w-10/12">
                <img src={image.file.url} alt={image.title} />
              </Parallax>
            </div>
          </div>
        </Content>

        <Content tw="row-span-1 space-y-20 mb-32 md:(space-y-32) lg:(px-0)">
          <div tw="flex flex-col w-full relative md:(flex-row items-center)">
            <div tw="flex flex-col justify-center relative md:(w-5/12)">
              <div tw="mb-4 flex items-center space-x-2 text-gray-400">
                <div tw="w-6 h-6 flex items-center justify-center rounded-full border border-gray-400">
                  <FontAwesomeIcon tw="w-3 h-3" icon={['fal', appIcon]} />
                </div>
                <p tw="capitalize text-sm leading-none">{appPre}</p>
              </div>
              <PhaseSecondaryTitle tw="w-5/6 md:(max-w-xs)">
                {moduleTitle}
              </PhaseSecondaryTitle>
              <Description tw="max-w-xs">{appDescription}</Description>
            </div>

            <div tw="md:(w-7/12 pl-4)">
              <MxSectionSecondContainer tw="grid grid-cols-1 overflow-hidden bg-gray-50 rounded-t-lg md:(gap-x-0 gap-y-0)">
                <div tw="row-start-1 border-b border-gray-200 h-8 flex items-center col-span-full">
                  <div tw="ml-3 space-x-1 flex">
                    <div tw="w-3 h-3 rounded-full border border-gray-600"></div>
                    <div tw="w-3 h-3 rounded-full border border-gray-600"></div>
                    <div tw="w-3 h-3 rounded-full border border-gray-600"></div>
                  </div>
                </div>
                <div tw="row-start-3 h-auto m-4 overflow-hidden relative col-span-full">
                  <div tw="rounded-full absolute right-20 top-20 z-30 md:(top-36 right-10)">
                    <div tw="w-6 h-6 flex shadow-3xl items-center justify-center rounded-full relative bg-black">
                      <p tw="text-white text-sm leading-none">03</p>
                      <div tw="rounded-full absolute top-0 bottom-0 right-0 left-0 transform animate-ping -z-1 bg-black" />
                    </div>
                  </div>
                  <div tw="rounded-full absolute left-24 bottom-20 z-30 md:(bottom-36 left-16)">
                    <div tw="w-6 h-6 flex shadow-3xl items-center justify-center rounded-full relative bg-black">
                      <p tw="text-white text-sm leading-none">02</p>
                      <div tw="rounded-full absolute top-0 bottom-0 right-0 left-0 transform animate-ping -z-1 bg-black" />
                    </div>
                  </div>
                  <div tw="rounded-full absolute left-20 top-12 z-30 md:(top-40 left-40)">
                    <div tw="w-6 h-6 flex shadow-3xl items-center justify-center rounded-full relative bg-black">
                      <p tw="text-white text-sm leading-none">01</p>
                      <div tw="rounded-full absolute top-0 bottom-0 right-0 left-0 transform animate-ping -z-1 bg-black" />
                    </div>
                  </div>
                  <GatsbyImage
                    alt={appPhotos[0]?.title}
                    tw="h-full"
                    image={
                      appPhotos[0]?.localFile.childImageSharp.gatsbyImageData
                    }
                  />
                </div>
              </MxSectionSecondContainer>
            </div>
          </div>
          <div tw="flex flex-col w-full relative md:(flex-row items-center)">
            <div tw="py-2 md:(w-5/12 order-2)">
              <PhaseSecondaryTitle tw="w-5/6">
                {beyondKitchen?.moduleTitle}
              </PhaseSecondaryTitle>
              <Description tw="md:(my-6)">
                {beyondKitchen?.moduleMisc?.description}
              </Description>

              <div tw="space-y-3 text-gray-500">
                {beyondKitchen?.moduleMisc?.rooms.map(
                  (room: string, idx: number) => (
                    <div tw="flex space-x-2 items-center" key={idx}>
                      <div>
                        <CheckIcon />
                      </div>
                      <CategoryListText>{room}</CategoryListText>
                    </div>
                  ),
                )}
              </div>
            </div>

            <div tw="mt-4 md:(w-7/12 order-1 mr-16 mt-0)">
              <MxSectionSecondContainer tw="grid grid-cols-9 gap-x-3 gap-y-3 overflow-hidden relative md:(grid-cols-9 gap-x-3 gap-y-3)">
                <div tw="h-36 col-span-full md:(h-auto)">
                  <GatsbyImage
                    alt="kitchen design"
                    tw="h-full"
                    image={
                      beyondKitchen?.contentSlots?.[0]?.photos[0]?.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                </div>
                <div tw="h-36 col-span-3 md:(col-span-3 h-60)">
                  <GatsbyImage
                    alt="kitchen design"
                    tw="h-full"
                    image={
                      beyondKitchen?.contentSlots?.[1]?.photos[0]?.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                </div>
                <div tw="h-36 col-span-3 md:(col-span-3 h-60)">
                  <GatsbyImage
                    alt="kitchen design"
                    tw="h-full"
                    image={
                      beyondKitchen?.contentSlots?.[2]?.photos[0]?.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                </div>
                <div tw="h-36 col-span-3 md:(col-span-3 h-60)">
                  <GatsbyImage
                    alt="kitchen design"
                    tw="h-full"
                    image={
                      beyondKitchen?.contentSlots?.[3]?.photos[0]?.localFile
                        .childImageSharp.gatsbyImageData
                    }
                  />
                </div>
              </MxSectionSecondContainer>
            </div>
          </div>
          <div tw="flex flex-col w-full md:(items-center flex-row)">
            <div tw="flex flex-col md:(w-5/12)">
              <PhaseSecondaryTitle tw="w-5/6">
                {measurementsTitle}
              </PhaseSecondaryTitle>
              <Description tw="max-w-xs">{measurementsDescription}</Description>
            </div>
            <div tw="md:(w-7/12)">
              <MxSectionSecondContainer tw="grid grid-cols-1 overflow-hidden bg-gray-50 rounded-t-lg md:(gap-x-0 gap-y-0)">
                <div tw="border-b row-start-1 h-8 flex items-center col-span-full">
                  <div tw="ml-3 space-x-1 flex">
                    <div tw="w-3 h-3 rounded-full border border-gray-600"></div>
                    <div tw="w-3 h-3 rounded-full border border-gray-600"></div>
                    <div tw="w-3 h-3 rounded-full border border-gray-600"></div>
                  </div>
                </div>
                <div tw="row-start-3 h-auto m-4 overflow-hidden relative col-span-full">
                  <div tw="w-full h-full overflow-hidden">
                    <GatsbyImage
                      alt="kitchen design"
                      tw="h-full"
                      image={
                        measurementsPhotos[0]?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                    />
                  </div>
                </div>
              </MxSectionSecondContainer>
            </div>
          </div>
        </Content>
      </Container>
    </Section>
  )
}

export { PhaseThree }
